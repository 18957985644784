@import "sass-easing";

main.quiz {
  overflow-x: hidden;
  button.next {
    width: 8.125rem;
    margin-left: calc(80% - 8.125rem);
    &.results {
      width: 15.625rem;
      margin-left: calc(80% - 15.625rem);
    }
  }

  &.transitioning button.next:focus {
    outline: none;
  }

  header,
  article {
    height: 100%;
    width: 50vw;
    position: absolute;
  }
  header {
    background: $gray;
    left: 0;
    h1 {
      margin-top: 20vh;
      margin-left: 15%;
      width: 70%;
      text-align: left;
      font-size: 2.5rem;
      transform: translate3d(0, 0, 2px);
      span {
        font-family: $serif-type;
        color: $dark-gray;
        font-size: 2.25rem;
        span {
          color: $red;
          font-size: 2.25rem;
        }
      }
    }
    h2 {
      top: 16vh;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 0.75rem;
      left: 15%;
      margin: 0;
      text-align: left;
      position: absolute;
      letter-spacing: 0.05rem;
    }

    .image-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 56vh;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &.left {
        justify-content: flex-start;
      }
    }

    .quiz-image {
      pointer-events: none;
      max-height: 100%;
      &.short,
      &.float,
      &.bottom {
        max-width: 100%;
      }
      &.bottom {
        // width: 80%;
        // left: 10%;
      }
      &.float {
        bottom: 5%;
        // width: 80%;
        // left: 10%;
      }
      &.bottom,
      &.rise {
        bottom: 0;
        transform-origin: center bottom;
        &.left {
          transform-origin: left bottom;
        }
      }
      &.long {
        height: 56vh;
        width: calc(56vh * 3.75);
        position: absolute;
        left: calc(50% - (0.5 * (56vh * 3.75)));

        &.balloons {
          width: calc(56vh * 4.43);
          left: calc(50% - (0.5 * (56vh * 4.43)));
        }
        &.blocks {
          width: calc(56vh * 4.15);
          left: calc(50% - (0.5 * (56vh * 4.15)));
        }
      }
      &.helix {
        width: 100%;
        height: auto;
        max-height: 300%;
        left: 0;
        top: 0;
        position: absolute;
      }
    }
  }

  &.white header,
  &.white3 header {
    background: $white;
  }

  &.light-blue header {
    background: $light-blue;
  }
  &.light-blue2 header {
    background: $light-blue-alt;
  }

  &.purple header {
    background: $purple;
  }

  &.pink header {
    background: $pink;
  }

  &.pink2 header {
    background: $pink-alt;
  }

  &.yellow header {
    background: $yellow;
  }

  &.orange header {
    background: $orange;
  }

  &.white2 header {
    background: $white-3;
  }

  article {
    background: $gray-2;
    left: 50vw;
    h3 {
      position: absolute;
      top: 15vh;
      left: 15%;
      font-size: 0.75rem;
      font-weight: 400;
      margin: 0;

      span span {
        font-weight: bold;
      }
    }
  }

  &.white article,
  &.white2 article {
    background: $white;
  }

  &.light-blue article,
  &.light-blue2 article {
    background: $light-blue-2;
  }

  &.purple article {
    background: $purple-2;
  }

  &.pink article {
    background: $pink-2;
  }

  &.pink2 article {
    background: $pink-alt-2;
  }

  &.yellow article {
    background: $yellow-2;
  }

  &.orange article {
    background: $orange-2;
  }

  &.white3 article {
    background: $white-3;
  }

  button.answer {
    background: transparent;
    border: none;
    display: block;
    width: calc(65%);
    margin: 1rem;
    padding: 0.625rem;
    margin-left: calc(15%);
    font-size: 1.25rem;
    padding-left: 4.75rem;
    text-align: center;
    min-height: 3.75rem;
    position: relative;
    font-family: "Acumin Pro";
    line-height: 120%;
    border: 1px solid $black;
    border-radius: 0.25rem;
    color: $black;
    text-align: left;
    &.over-three-answers {
      font-size: 1rem;
    }
    &:first-of-type {
      margin-top: 20vh;
    }
    &:before {
      content: "";
      z-index: 1;
      position: absolute;
      left: 1.375rem;
      top: calc(50% - 0.75rem);
      width: 1.5rem;
      height: 1.5rem;
      background: transparent;
      border: 1px solid $black;
      border-radius: 50%;
    }
    &.selected {
      animation: pulse 0.8s $easeInOutCubic both;
    }
    &.already-selected {
      opacity: 0.5;
    }
    &.selected:after,
    &.already-selected:after {
      content: "";
      z-index: 1;
      font-family: "Acumin Pro";
      position: absolute;
      left: 1.625rem;
      top: calc(50% - 0.5rem);
      width: 1.125rem;
      height: 1.125rem;
      background: $black;
      border-radius: 50%;
      animation: fill-from-bottom $exit-timing-fast-1 $easeOutCubic both;
    }
    &.selected.second-rank:before,
    &.selected.first-rank:before {
      background: $black;
    }
    &.selected.first-rank:after,
    &.already-selected.first-rank:after,
    &.selected.second-rank:after {
      background: $black;
      content: "1";
      color: $white;
      transform: translate(0, -1px);
      font-weight: bold;
      font-size: 1rem;
      text-align: center;
    }
    &.selected.second-rank:after {
      content: "2";
    }
    p {
      margin-top: 0.3rem;
      display: block;
    }
  }
  &.previous {
    transform: translate3d(100vw, 0, 0);
    pointer-events: none;
  }
  &.transitioning {
    transition: all $transition-timing ease;
    pointer-events: none;
  }
  &.next {
    /* this is the new page that appears during a transition */
    transform: translate3d(100vw, 0, 0);
    pointer-events: none;
    header {
      overflow: hidden;
      h1 {
        transition: opacity 0.2s $entrance-delay ease,
          clip-path $entrance-timing $easeOutCubic;
        opacity: 0;
        clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
      }
      .quiz-image {
        transition: transform $entrance-timing-fast-2 $easeOutCubic;
        transform: scale(0);
        &.rise {
          transform: translate3d(0, 100vh, 0);
        }
      }
    }
    &.chomp {
      header {
        transition: transform $transition-timing $easeInOutCubic;
        transform: translate3d(-100%, 0, 0);
      }
      article {
        transition: transform $transition-timing $easeInOutCubic;
        transform: translate3d(100%, 0, 0);
      }
    }
    &.vertical-cross {
      header {
        transition: transform $transition-timing $easeInOutCubic;
        transform: translate3d(0, 100%, 0);
      }
      article {
        transition: transform $transition-timing $easeInOutCubic;
        transform: translate3d(0, -100%, 0);
      }
    }

    &.transitioning {
      transform: translate3d(0, 0, 0);
      header {
        h1 {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          opacity: 1;
        }
        .quiz-image {
          transform: scale(1);
        }
      }
      &.parallax {
        transform: translate3d(0, 0, 0);
        transition: all 0s linear;
        header {
          transform-origin: left center;
          animation: parallax-header-in $transition-timing $easeInOutCubic both;
        }
        article {
          animation: parallax-article-in $transition-timing $easeInOutCubic both;
        }
      }
      &.chomp,
      &.vertical-cross {
        transform: translate3d(0, 0, 0);
        transition: all 0s linear;
        header {
          transform: translate3d(0, 0, 0);
        }
        article {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
  &.current {
    /* this is the outgoing page during a transition */
    transform: translate3d(0, 0, 0);
    header {
      h1 {
        transition: opacity 0.2s 0s ease, clip-path $exit-timing $easeOutCubic;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        opacity: 1;
      }
      .quiz-image {
        transition: transform $exit-timing-fast-2 $easeOutCubic;
        transform: scale(1);
      }
    }
    &.transitioning {
      transform: translate3d(-100vw, 0, 0);
      &.parallax-out,
      &.chomp-out {
        transform: translate3d(0, 0, 0);
      }
      pointer-events: none;
      header {
        h1 {
          clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          opacity: 0;
        }
        .quiz-image {
          transform: scale(0);
          &.rise {
            transform: translate3d(0, 100vh, 0);
          }
          &.bottom {
            animation: none;
          }
        }
      }
      &.vertical-cross-out {
        transform: translate3d(0, 0, 0);
        transition: all 0s linear;
        header {
          transition: transform $transition-timing $easeInOutCubic;
          transform: translate3d(0, -100%, 0);
        }
        article {
          transition: transform $transition-timing $easeInOutCubic;
          transform: translate3d(0, 100%, 0);
        }
      }
    }
  }
  &.first-page {
    header h1 {
      animation: fill-from-bottom 1s 1s $easeOutCubic both;
    }
    h2,
    article {
      animation: appear 0.5s 1.5s $easeOutCubic both;
    }
    .quiz-image.bottom {
      animation: scale-in 0.5s 1.5s $easeOutCubic both;
    }
  }
  &.first-page.transitioning.current {
    header h1 {
      animation: clip-to-top 1s 0s $easeOutCubic both;
    }
    h2 {
      animation: disappear 1s 0s linear both;
    }
    .quiz-image.bottom {
      animation: scale-out 0.5s 0.5s $easeOutCubic both;
    }
  }
  @include desktop-small {
    header h1 {
      font-size: 2.25rem;
    }
  }
}

main.quiz article button.answer,
main.quiz h2,
main.quiz h3 {
  transition: opacity $exit-timing-fast-1 linear;
  opacity: 1;
}

main.quiz.next article button.answer,
main.quiz.next h2,
main.quiz.next h3 {
  transition: opacity $entrance-timing-fast-2 linear;
}

main.quiz.next.transitioned article button.answer,
main.quiz.next.transitioned h2,
main.quiz.next.transitioned h3,
main.quiz.current.transitioning article button.answer,
main.quiz.current.transitioning h2,
main.quiz.current.transitioning h3,
main.quiz.current.transitioning article button.next img,
main.quiz.next.transitioned article button.next img,
main.quiz.next.transitioning article button.next img,
main.quiz.next.transitioning article button.next p,
main.quiz.current.transitioning article button.next p,
main.quiz.next.transitioned article button.next p {
  opacity: 0;
}

main.quiz.next.transitioned article button.next img,
main.quiz.next.transitioned article button.next p {
  opacity: 1;
  transition: opacity 0.7s linear;
}

main.quiz.current.transitioning article button.next,
main.quiz.next.transitioned article button.next {
  border: none;
}

main.quiz.current.transitioning article button.next {
  animation: circle-out $exit-timing $easeOutCubic both;
}

main.quiz.next.transitioning article button.next {
  animation: circle-in $entrance-timing-fast-1 $easeInCubic both;
}

main.quiz.next.transitioning article button.results {
  animation: circle-in-results $entrance-timing-fast-1 $easeOutCubic both;
}

main.quiz.transitioning.current article button.next.second-step p {
  opacity: 0 !important;
  animation: none;
}

.App main.quiz article button.results.quiz-ended {
  p,
  img {
    opacity: 0;
  }
  border: 0;
  animation: circle-end-quiz 1.5s $easeOutCubic both;
  z-index: 1000;
}

.App main.quiz article button.results.quiz-ended:focus,
main.quiz article button.next.second-step:focus {
  outline: none;
}
// $easeInOutQuad  : cubic-bezier(0.455, 0.030, 0.515, 0.955);
// $easeInOutCubic : cubic-bezier(0.645, 0.045, 0.355, 1.000);
// $easeInOutQuart : cubic-bezier(0.770, 0.000, 0.175, 1.000);
// $easeInOutQuint : cubic-bezier(0.860, 0.000, 0.070, 1.000);
// $easeInOutSine  : cubic-bezier(0.445, 0.050, 0.550, 0.950);
// $easeInOutExpo  : cubic-bezier(1.000, 0.000, 0.000, 1.000);
// $easeInOutCirc  : cubic-bezier(0.785, 0.135, 0.150, 0.860);
// $easeInOutBack  : cubic-bezier(0.680, -0.550, 0.265, 1.550);

@media (max-height: 850px) {
  main.quiz article h3 {
    top: 6vh;
  }
  main.quiz article button.answer.over-three-answers:first-of-type {
    margin-top: 10vh;
  }
}

@include tablet {
  .App {
    main.quiz {
      &.next.transitioned {
        position: absolute;
        height: 0;
        transform: translate3d(100vw, 0, 0);
      }
      article,
      header {
        width: 100vw;
        position: relative;
      }
      header {
        height: 29rem;
        h1 {
          font-size: 2rem;
          top: 6rem;
          margin-top: 0;
          position: absolute;
          span {
            font-size: 1.25rem;
          }
        }
        h2 {
          top: 4rem;
        }
        .image-container {
          height: 16rem;
          img.long {
            height: 16rem;
            width: calc(16rem * 3.75);
            position: absolute;
            left: calc(50% - (0.5 * (16rem * 3.75)));
            &.balloons {
              width: calc(16rem * 4.43);
              left: calc(50% - (0.5 * (16rem * 4.43)));
            }
            &.blocks {
              width: calc(16rem * 4.15);
              left: calc(50% - (0.5 * (16rem * 4.15)));
            }
          }
        }
      }
      article {
        // top: 29rem;
        left: 0;
        height: auto;
        min-height: calc(100vh - 29rem);
        padding-top: 4rem;
        padding-bottom: 4rem;

        h3 {
          top: 2rem;
          margin-top: 0;
          left: 4rem;
        }

        button.answer,
        button.next {
          margin-left: 4rem;
          margin-right: 4rem;
          width: calc(100% - 8rem);
        }

        button.answer:first-of-type {
          margin-top: unset;
        }
      }

      &.next {
        &.vertical-cross {
          header {
            transform: translate3d(100%, 0, 0);
          }
          article {
            transform: translate3d(-100%, 0, 0);
          }
        }
        &.chomp {
          header {
            transform: translate3d(0, -100%, 0);
          }
          article {
            transform: translate3d(0, 100%, 0);
          }
        }
      }
      &.current {
        header {
          transform: translate3d(0, 0, 0);
        }
        article {
          transform: translate3d(0, 0, 0);
        }
      }

      &.transitioning {
        &.current {
          &.vertical-cross-out {
            header {
              transform: translate3d(-100%, 0, 0);
            }
            article {
              transform: translate3d(100%, 0, 0);
            }
          }
        }
        &.next {
          height: auto;
          transform: translate3d(0, 0, 0);
          transition: all 0s linear;
          position: absolute;
          top: 0;
          &.vertical-cross,
          &.chomp {
            header {
              transform: translate3d(0, 0, 0) !important;
            }
            article {
              transform: translate3d(0, 0, 0) !important;
            }
          }
          button.next {
            animation-name: circle-in-phone;
          }
        }
        &.current {
          button.next {
            animation-name: circle-out-phone;
          }
        }
      }
      article button.results.quiz-ended {
        animation-name: circle-end-quiz-phone !important;
      }
    }
  }
}

@include phone {
  .App {
    main.quiz {
      header {
        height: 24rem;
        h1 {
          margin-left: 2rem;
          width: calc(100vw - 4rem);
          font-size: 1.5rem;
          span {
            font-size: 1.25rem;
          }
        }
        h2 {
          left: 2rem;
        }
        .image-container.bottom {
          height: 50%;
        }
        .image-container.float {
          height: 55%;
        }
        .image-container.rise.long {
          height: 13rem;
          img.long {
            height: 13rem;
            position: absolute;
            left: calc(50% - (0.5 * (13rem * 3.75)));
            width: calc(13rem * 3.75);
            &.balloons {
              width: calc(13rem * 4.43);
              left: calc(50% - (0.5 * (13rem * 4.43)));
            }
            &.blocks {
              width: calc(13rem * 4.15);
              left: calc(50% - (0.5 * (13rem * 4.15)));
            }
          }
        }
      }
      article {
        min-height: calc(100vh - 24rem);

        h3 {
          left: 2rem;
        }
        button.answer,
        button.next {
          margin-left: 2rem;
          margin-right: 2rem;
          width: calc(100% - 4rem);
        }
      }
    }
    footer {
      .sponsor-message {
        left: 6rem;
        font-size: 0.6rem;
        top: calc(3.5vh - 0.25rem);
      }
    }
  }
}

.App.spanish {
  main.quiz {
    header h1 {
      font-size: 2.25rem;
      @include tablet {
        font-size: 1.9rem;
      }
      @include phone {
        font-size: 1.35rem;
      }
    }
  }
}
