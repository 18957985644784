@import "./styles/vars.scss";
@import "./styles/Results.scss";
@import "./styles/Quiz.scss";
@import "./styles/Home.scss";

html,
body {
  font-size: 16px;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  overflow-x: hidden;
}

* {
  font-family: "Acumin Pro";
}

h1 {
  font: $quiz-header;
}

a {
  text-decoration: none;
}

main {
  width: 100vw;
  height: 93vh;
  top: 0;
  position: absolute;
  transform: translate3d(0, 0, 0);
  overflow: auto;
}

footer {
  width: 100vw;
  height: $footer-height;
  bottom: 0;
  position: fixed;
  background: $black-2;
  .fhi-logo {
    top: 30%;
    height: 40%;
    width: auto;
    left: 2rem;
    position: absolute;
  }
  .sponsor-message {
    margin: 0;
    left: 8rem;
    top: calc(3.5vh - 0.375rem);
    font-size: 0.75rem;
    line-height: 100%;
    position: absolute;
    color: $white;
  }
}

header.App-header {
  z-index: 240;
  position: absolute;

  #stem-logo {
    margin-left: 2rem;
    height: 1rem;
    width: auto;
    margin-top: 1.5rem;
  }
}

.App-header h4 {
  text-align: left;
  font-family: $serif-type;
  margin-left: 2rem;
  font-size: 1rem;
  font-weight: 400;
  color: $black;
}

button p {
  margin: 0;
  display: inline;
}

button img.button-arrow {
  display: inline-block;
  height: 1rem;
  transform: translate(0.125rem, 0.125rem);
}

button {
  cursor: pointer;
}

button.red {
  color: $white;
  background: $red;
  border: $red-border;
  border-radius: 3px;
  font-size: 1.125rem;
  height: 4.375rem;
  letter-spacing: 0.05rem;
  display: block;
  position: relative;
  text-decoration: none;
  animation: pulse 2s $easeInOutCubic infinite;
}
button.red.disabled {
  pointer-events: none;
  opacity: 0.5;
  animation: none;
}

form {
  height: 100vh;
  width: 100vw;
  background: $red;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type="password"] {
  margin-top: 48vh;
  border: none;
  border-radius: 50px;
  height: 50px;
  font-size: 46px;
  padding: 10px 50px;
  width: 400px;
  text-align: center;
  z-index: 10000;
}

@include tablet {
  .App {
    overflow: auto;
    overflow-x: hidden;
    main {
      height: unset;
      min-height: 100vh;
      position: relative;
    }
    footer {
      position: relative;
      bottom: unset;
    }
  }
}

@include desktop-short {
  .App.home,
  .App.quiz {
    min-width: 1024px;
    height: 700px;
    overflow-x: hidden;
    overflow-y: hidden;
    &.home {
      height: 100vh;
      overflow-x: auto;
      overflow-y: auto;
    }
    main {
      min-width: 1024px;
      height: 650px;
      overflow-y: hidden;
      &.home {
        height: auto !important;
        min-height: 700px;
        position: relative !important;
        overflow-y: scroll;
        header h1 {
          font-size: 2rem;
        }
      }
      &.results {
        height: inherit;
        margin-bottom: 200vh;
      }
      article {
        height: 93vh;
        overflow-y: scroll;
        .answers-area {
          margin-bottom: 250px;
          height: auto;
          min-height: 1024px;
        }
      }
    }
  }
}

@include desktop-windowed {
  .App.home,
  .App.quiz {
    width: 1024px;
    height: 700px;
    overflow-x: hidden;
    overflow-y: hidden;
    &.home {
      overflow-x: auto;
      overflow-y: auto;
    }
    main {
      width: 1024px;
      height: 650px;
      overflow-y: hidden;
      &.home {
        height: 700px !important;
        position: relative !important;
        overflow-y: scroll;
        padding-bottom: 200px;
        header h1 {
          font-size: 2rem;
        }
      }
      &.results {
        height: inherit;
        margin-bottom: 200vh;
      }
      header {
        // .image-container {
        //   max-height: calc(50vh - 100px);
        // }
      }
      article {
        height: 93vh;
        overflow-y: scroll;
        .answers-area {
          margin-bottom: 250px;
          height: auto;
          min-height: 1024px;
        }
      }
    }
  }
}
#language-select {
  font-family: "Acumin Pro", sans-serif;
  letter-spacing: 0.1rem;
  top: 1.5rem;
  height: 2rem;
  left: calc(100vw - 12rem);
  width: 10rem;
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  position: absolute;
  cursor: pointer;
  color: black;

  @include desktop-windowed {
    left: 8rem;
    justify-content: flex-start;
  }
  .selected {
    font-weight: 700;
  }
  span {
    display: inline-block;
    height: 2rem;
    position: relative;
  }
  .divider {
    padding: 0 0.2rem;
  }
  .english {
    @include tablet {
      max-width: 2rem;
      overflow: hidden;
    }
    @include desktop-windowed {
      max-width: 2rem;
      overflow: hidden;
    }
  }
  .spanish {
    @include tablet {
      max-width: 1.7rem;
      overflow: hidden;
    }
    @include desktop-windowed {
      max-width: 1.7rem;
      overflow: hidden;
    }
  }
}
