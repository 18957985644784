@import "/vars.scss";

main.results {
  width: 100vw;
  overflow-x: hidden;
  > header {
    width: 100vw;
    height: 21.875rem;
    background: $red;

    > h1 {
      width: 38.875rem;
      font-size: 3.375rem;
      top: 100px;
      animation: swipe-up 0.8s 0s $easeOutCubic both;
    }

    > p {
      top: 200px;
      width: 400px;
      animation: appear 0.8s 1s linear both;
    }

    > h1,
    > p {
      color: $white;
      text-align: left;
      position: absolute;
      left: calc(50% - 280px);
    }

    > img.results-header {
      width: 190px;
      position: absolute;
      top: 120px;
      left: calc(50% - 500px);
      animation: scale-in 0.8s 0.7s $easeOutCubic both;
    }
  }

  article {
    animation: appear 0.4s 1.6s linear both;
  }

  article.results {
    margin-top: 42px;
    margin-left: calc(50% - 280px);
    width: 600px;
    .debug {
      display: none;
    }

    .result {
      width: 100%;
      border-top: 1px solid $black;
      position: relative;
      margin-top: 23px;
      padding-top: 0;
      .number {
        background: $black;
        height: 33px;
        width: 34px;
        top: 0;
        left: 0;
        position: absolute;

        p {
          color: white;
          font-size: 14px;
          margin: 0;
          transform: translateY(9px);
        }
      }

      .links-holder {
        position: relative;
        height: 1.5rem;
        width: 3.5rem;
        i.fa {
          color: $black;
          text-decoration: none;
          font-size: 1.5rem;
          left: 3.3rem;
          top: 2.5rem;
          position: absolute;
          top: 0;
        }

        .fa-twitter-square {
          margin-left: 2rem;
        }
      }

      h1 {
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-size: 20px;
        margin-left: 3.375rem;
        margin-bottom: 0.5rem;
        text-align: left;
        letter-spacing: 1px;
        color: $black;
      }
      .debug {
        position: absolute;
        right: 0;
        top: 0.5rem;
        margin: 0;
        opacity: 0.3;
      }

      .expandable-description {
        max-height: none;
        overflow: hidden;
        transition: max-height 0.5s ease;
        position: relative;
        margin-bottom: 16px;
      }

      .expandable-description.hidden {
        max-height: 80px;
      }

      .expandable-description.hidden:after {
        content: " ";
        width: 100%;
        height: 40px;
        bottom: 0;
        left: 0;
        position: absolute;
        background: linear-gradient(
          to top,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .description-text {
        font-size: 14px;
        color: $dark-gray;
        line-height: 140%;
        margin-left: 3.375rem;
        text-align: left;
        margin-bottom: 0;
      }

      button {
        background: transparent;
        border: none;
        font-weight: 700;
        font-size: 0.75rem;
        margin-left: 3.375rem;
        color: $link-blue;
        display: block;
        padding: 0;
      }
      button:focus {
        outline: none;
      }
      .expert-button{
        color: #fa4b4b;
        margin-top: 15px;
        font-size: 1em;
        text-align: left;
        &:hover,
        &:active{
          text-decoration: underline;
        }
      }
    }
  }
  article.next-steps {
    position: relative;
    width: 100vw;
    border-top: 1px solid $black;
    margin-top: 4rem;
    height: 50rem;

    h1 {
      font-size: 4rem;
      margin-bottom: 0;
    }

    p {
      font-size: 20px;
      color: $dark-gray;
      line-height: 160%;
      width: 600px;
      letter-spacing: 1px;
      margin-left: calc(50% - 300px);
    }
    button.red {
      display: inline-block;
      width: 400px;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
    }
    section.try-again,
    section.show-all {
      bottom: 0;
      position: absolute;
      height: 20rem;
      width: 50vw;
      display: inline-block;
      border-top: 1px solid $black;
      padding: 1rem 0;
      h2 {
        font-family: $serif-type;
        font-size: 36px;
        font-weight: 400;
        width: 50%;
        margin-left: 25%;
        margin-top: 6rem;
        margin-bottom: 0;
      }
      button {
        height: 90px;
        width: 100%;
        margin: 0;
        padding: 0;
        position: relative;
        background: transparent;
        border: none;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        div {
          max-width: calc(100% - 3rem);
          text-align: center;
        }
        img {
          height: 3rem;
          width: auto;
        }
      }
    }
    section.try-again {
      left: 0;
      background: black;
      * {
        color: white;
      }
      button .flipped {
        transform: rotateZ(180deg);
      }
    }
    section.show-all {
      button {
        color: $red;
        &:focus {
          outline: none;
        }
      }
    }
  }
  
}
.loading-counter {
  width: 4rem;
  left: calc(50vw - 2rem - 7px);
  text-align: center;
  display: block;
  top: 372px;
  font-weight: 700;
  position: absolute;
  color: $white;
  font-size: 0.85rem;
  letter-spacing: 1px;
  margin: 10px 10px;
}
.progress-holder,
.progress {
  width: 4rem;
  height: 4rem;
  left: calc(50vw - 2rem - 7px);
  position: absolute;
  top: 350px;
  margin: 0;
  padding: 0;
  background: transparent;
}

.progress-holder {
  border: 7px solid transparent;
  transform-origin: center top;
  animation: expand 1.6s 4s $easeOutCubic both;
}
.progress {
  border: 7px solid white;
  border-bottom: 7px solid transparent;
  background: transparent;
  border-radius: 50%;
  top: -7px;
  left: -7px;
  animation: load 4.7s 0s $easeOutCubic both;
}

.progress:after {
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  top: -3px;
  left: -3px;
  position: absolute;
  content: " ";
  border: 1px solid white;
  border-radius: 50%;
}

main.loading-results {
  background: $red;
  height: 100vh;
  overflow: hidden;
  > header > h1 {
    left: 0;
    width: 100vw;
    top: 240px;
    font-size: 2rem;
    text-align: center;
    animation: swipe-up-down 5s $easeInOutCubic both;
  }
}

.expert-inner{
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-height: 410px;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 5px 17px -10px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 5px 17px -10px rgba(0,0,0,0.75);
  box-shadow: 0px 5px 17px -10px rgba(0,0,0,0.75);
}
.expert-description{
  text-align: left;
  padding: 30px;
  overflow-x: auto;
  max-height: 350px;
  overflow-x: hidden;
  &:after{
    content: " ";
    width: 100%;
    height: 40px;
    bottom: 0;
    left: 0;
    position: absolute;
    background: linear-gradient(to top, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  }
  h2{
    color: #fa4b4b;
    font-size: 1.9em;
    font-weight: normal;
    font-family: "BigCaslon", "Garamond", "Georgia", serif;
    margin-bottom: 0;
  }
  h3{
    font-size: 1.1em;
    margin-top: 5px;
    margin-bottom: 0;
  }
}
.expert-credit{
  font-size: .8em;
  word-break: break-all;
}
.expert-image{
  position: relative;
  img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: top;
  }
  &:after{
    content:'';
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
    width: 100%;
    height: 100px;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.expert-mobile-name{
  text-align: left;
  position: absolute;
  bottom: 30px;
  left: 30px;
  display: none;
  z-index: 2;
  h2{
    color: #ffb2b2;
    font-family: "BigCaslon", "Garamond", "Georgia", serif;
    font-size: 1.9em;
    font-weight: normal;
    margin: 0;
  }
  h3{
    color: white;
    margin-top: 5px;
    margin-bottom:0;
  }
}
.expert-close{
  position: absolute;
  right: 15px;
  top: 15px;
  border-radius: 100%;
  text-align: center;
  font-weight: normal;
  border: 0;
  transform: rotate(45deg);
  background-color: white;
  border-radius: 100%;
  width: 31px;
  height: 31px;
  font-size: 0;
  z-index: 9;
  &:before{
    content:'+';
    width: 100%;
    height: 100%;
    color: #fa4b4b;
    position: absolute;
    top: 2px;
    left: 1px;
    display: block;
    z-index: 3;
    font-size: 26px;
  }
  &:hover:before,
  &:active:before{
    color: #000000;
  }
}


@include tablet {
  main.results {
    > header {
      > h1 {
        left: 2rem;
        width: calc(100vw - 4rem);
        text-align: center;
        font-size: 3rem;
      }
      > img.results-header {
        width: 15vw;
        top: 2rem;
        left: 42.5vw;
      }
      p {
        text-align: center;
        left: 20vw;
        width: calc(60vw);
      }
    }
    > article.results {
      margin-left: 2rem;
      width: calc(100vw - 4rem);
      .debug {
        display: none;
      }
      .expandable-description.expanded {
        max-height: none;
      }
    }
    > article.next-steps {
      h1 {
        font-size: 3rem;
      }
      p {
        font-size: 1rem;
      }
      button.red,
      button,
      button > * {
        font-size: 1rem;
      }
      section.try-again,
      section.show-all {
        h2 {
          width: calc(100% - 4rem);
          left: 0;
          margin-left: 2rem;
          font-size: 1.75rem;
        }
      }
    }
  }
}

@include phone {
  main.results {
    > header {
      > h1 {
        font-size: 1.85rem;
      }
    }
    > article.next-steps {
      height: unset;
      h1 {
        font-size: 2rem;
      }
      p,
      button.red {
        width: calc(100vw - 4rem);
      }
      p {
        margin: 2rem;
      }
      section.try-again {
        margin-top: 4rem;
        button img {
          margin-left: -2rem;
        }
      }
      section.show-all {
        button img {
          margin-right: -2rem;
        }
      }

      section.try-again,
      section.show-all {
        display: block;
        position: relative;
        height: unset;
        width: 100vw;
        h2 {
          font-size: 1.6rem;
          margin-top: 2rem;
        }
        button {
          margin-bottom: 2rem;
        }
      }
    }
  }
  .expert-inner{
    display: flex;
    flex-direction: column-reverse;
    max-height: none;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .expert-description{
    max-height: 200px;
  }
  .expert-image{
    height: 250px;
  }
  .expert-desktop-name{
    display: none;
  }
  .expert-mobile-name{
    display: block;
  }
}

.App.spanish {
  main.results {
    > header h1 {
      font-size: 2.5rem;
      @include tablet {
        font-size: 2rem;
      }
      @include phone {
        font-size: 1.5rem;
      }
    }
    article.results {
      .result {
        .expandable-description.expanded {
          max-height:  none;
          @include tablet {
            max-height: none;
          }
          @include phone {
            max-height: none;
          }
        }
      }
    }
  }
}
