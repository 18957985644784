@import "./vars.scss";

main.home {
  background: $blue;
  animation: translate-from-right 0.6s 1s $easeOutCubic both;

  .background {
    left: 5vw;
    bottom: 0;
    width: 90vw;
    height: auto;
    position: absolute;

    animation: translate-from-bottom 0.8s 1.9s $easeOutCubic both;
  }

  header,
  article {
    position: absolute;
    left: 15%;
  }

  header h1 {
    font-size: 2.5rem;
    text-align: left;
    animation: fill-from-top 0.6s 1.6s $easeOutCubic both;
  }

  header {
    top: 15%;
    width: 40%;
  }

  article {
    height: 30%;
    width: 30%;
    top: 40%;
    animation: translate-from-left 0.9s 2.2s $easeOutCubic both;
    p {
      text-align: left;
      line-height: 160%;
    }
  }
  a {
    animation: appear 0.3s 2.9s linear both;
  }
  button.red {
    width: 100%;
    margin-top: 5%;
    position: fixed;
    left: 15%;
    width: 30%;
    bottom: 10%;
  }

  @include desktop-small {
    header h1 {
      font-size: 2.5rem;
    }
  }
}

@include tablet {
  main.home {
    height: 93vh;
    top: 0;
    overflow-x: hidden;
    width: 100vw;
    .background {
      height: 70%;
      width: auto;
      background-position: bottom right;
      bottom: 7vh;
      right: -15%;
      left: unset;
      top: unset;
    }
    header {
      width: 100vw;
      left: 0;
      top: 7vh;
      h1 {
        margin-left: 2rem;
        margin-right: 2rem;
        font-size: 3rem;
        width: calc(100% - 4rem) !important;
      }
    }
    article {
      width: 100vw;
      left: 0;
      top: 35vh;
      height: 53vh;
      p {
        margin-left: 2rem;
        width: calc(60% - 2rem);
        font-size: 1.15rem;
      }
    }
    button.red {
      width: calc(60% - 2rem) !important;
      left: 2rem;
      position: fixed;
      bottom: 13vh;
    }
  }

  main.home + footer {
    position: absolute;
    bottom: 0;
  }
}

@include phone {
  main.home {
    .background {
      right: -30%;
    }
    header {
      h1 {
        font-size: 2rem;
        width: calc(100% - 4rem) !important;
      }
    }
    article {
      height: 53vh;
      top: 35vh;
      p {
        font-size: 0.75rem;
        width: 60%;
      }
    }
    button {
      width: calc(100% - 4rem) !important;
    }
  }
}
