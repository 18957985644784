@import "sass-easing";

@font-face {
  font-family: "BigCaslon";
  src: url("../assets/Big-Caslon.woff") format("woff"); /* Pretty Modern Browsers */
  //      url('webfont.woff2') format('woff2'), /* Super Modern Browsers */
  //      url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
  //      url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../assets/AcuminPro-Regular.woff2") format("woff2"),
    url("../assets/AcuminPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Acumin Pro";
  src: url("../assets/AcuminPro-Bold.woff2") format("woff2"),
    url("../assets/AcuminPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

$black: #000;
$black-2: #1a1a1a;

$dark-gray: #666;

$white: #fff;
$white-2: #f3f3f3;
$white-3: #f6f6f6;

$red: #fa4b4b;
$red-2: #d33737;
$red-border: 1px solid $red-2;

$blue: #a3e3e3;

$light-blue: #91dcd8;
$light-blue-2: #b9e7e5;
$light-blue-alt: #a2e4e3;

$pink: #f7adcb;
$pink-2: #f9bdd4;

$pink-alt: #f6dde0;
$pink-alt-2: #f8e8eb;

$purple: #f9c6f1;
$purple-2: #fad7f7;

$yellow: #dad672;
$yellow-2: #e1de8d;

$orange: #f6c46a;
$orange-2: #f6ca7b;

$link-blue: #4444aa;

$gray: #d9d5d1;
$gray-2: #e8e5e1;

$serif-type: "BigCaslon", "Garamond", "Georgia", serif;

$exit-value: 1s;
$transition-value: 2s;
$entrance-value: $exit-value;

$exit-timing: $exit-value 0s;
$exit-timing-fast-1: calc(#{$exit-value} / 2) 0s;
$exit-timing-fast-2: calc(#{$exit-value} / 2) calc(#{$exit-value} / 2);

$transition-timing: $transition-value $exit-value;
$entrance-delay: calc(#{$exit-value} + #{$transition-value});

$entrance-timing: $entrance-value $entrance-delay;
$entrance-timing-fast-1: calc(#{$entrance-value} / 2) $entrance-delay;
$entrance-timing-fast-2: calc(#{$entrance-value} / 2)
  calc(#{$entrance-delay} + calc(#{$entrance-value} / 2));

/*sizing based on a 1280px x 808px mock! might need to modify */

$quiz-header: 48px $serif-type;

$career-header: 22px $serif-type;

$footer-height: 7vh;

@mixin desktop-short {
  @media (max-height: 700px) and (orientation: landscape) {
    @content;
  }
}

@mixin desktop-windowed {
  @media (max-height: 700px) and (max-width: 1023px) and (orientation: landscape) {
    @content;
  }
}

@mixin desktop-small {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1023px) and (orientation: portrait) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 639px) and (orientation: portrait) {
    @content;
  }
}

/* animations */

@keyframes load {
  0% {
    opacity: 1;
    transform: rotate3d(0, 0, 1, 0turn);
  }

  95% {
    opacity: 1;
    transform: rotate3d(0, 0, 1, 3turn);
  }
  100% {
    // opacity: 0;
    transform: rotate3d(0, 0, 1, 3turn);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes scale-out {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0, 0);
  }
}

@keyframes expand {
  0% {
    border: 7px solid transparent;
    border-radius: 50%;
    background-color: transparent;
    transform: scale(1, 1) translate3d(0, 0, 1px);
  }
  50% {
    border: 7px solid $white;
    border-radius: 50%;
    background-color: $white;
    transform: scale(1, 1) translate3d(0, 0, 1px);
  }
  100% {
    border: 7px solid $white;
    border-radius: 0;
    background-color: $white;
    transform: scale(20, 20) translate3d(0, 0, 1px);
  }
}

@keyframes swipe-up {
  0% {
    opacity: 0;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transform: translate3d(0, 0, 0);
  }
  1% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  100% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes swipe-up-down {
  0% {
    opacity: 0;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transform: translate3d(0, 0, 0);
  }
  1% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transform: translate3d(0, 0, 0);
    animation-timing-function: $easeInCubic;
  }
  10% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    // transform: translate3d(0, -120%, 0);
    animation-timing-function: $easeOutCubic;
  }
  16% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    // transform: translate3d(0, -100%, 0);
    animation-timing-function: $easeInSine;
  }
  78% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    // transform: translate3d(0, -100%, 0);
    animation-timing-function: $easeInCubic;
  }
  80% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    // transform: translate3d(0, -110%, 0);
    animation-timing-function: $easeOutCubic;
  }
  99% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transform: translate3d(0, 0, 0);
    animation-timing-function: $easeOutCubic;
  }
  100% {
    opacity: 0;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes parallax-header-in {
  0% {
    transform: translate3d(100vw, 0, 0) scale(1, 1);
    animation-timing-function: $easeInCubic;
  }
  35% {
    transform: translate3d(0, 0, 0) scale(2, 1);
    animation-timing-function: $easeOutCubic;
  }
  90% {
    transform: translate3d(0, 0, 0) scale(2, 1);
    animation-timing-function: $easeInCubic;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1, 1);
    animation-timing-function: $easeOutCubic;
  }
}

@keyframes parallax-article-in {
  0% {
    transform: translate3d(100vw, 0, 0) scale(1, 1);
    animation-timing-function: $easeInCubic;
  }
  50% {
    transform: translate3d(100vw, 0, 0) scale(1, 1);
    animation-timing-function: $easeOutCubic;
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1, 1);
    animation-timing-function: $easeOutCubic;
  }
}

@keyframes fill-from-top {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  100% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes fill-from-bottom {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  100% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes clip-to-bottom {
  0% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  99% {
    opacity: 1;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  100% {
    opacity: 0;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
}

@keyframes clip-to-top {
  0% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  99% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  100% {
    opacity: 0;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
}

@keyframes translate-from-bottom {
  0% {
    transform: translate3d(0, 100vh, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes translate-from-top {
  0% {
    transform: translate3d(0, -100vh, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes translate-from-right {
  0% {
    transform: translate3d(100vw, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes translate-from-left {
  0% {
    transform: translate3d(-100vw, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes circle-out {
  0% {
    width: 130px;
    border-radius: 3px;
    transform: translate3d(0, 0, 0) scale(1);
    animation-timing-function: $easeInCubic;
  }
  20% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(30px, 0, 0) scale(1);
    animation-timing-function: $easeOutCubic;
  }
  100% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(30px, 0, 0) scale(0);
    animation-timing-function: $easeOutCubic;
  }
}

@keyframes circle-out-phone {
  0% {
    width: calc(100vw - 8rem);
    border-radius: 3px;
    transform: translate3d(0, 0, 0) scale(1);
    animation-timing-function: $easeInCubic;
  }
  20% {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    transform: translate3d(calc(50vw - 4rem), 0, 0) scale(1);
    animation-timing-function: $easeOutCubic;
  }
  100% {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    transform: translate3d(calc(50vw - 4rem), 0, 0) scale(0);
    animation-timing-function: $easeOutCubic;
  }
}

@keyframes circle-end-quiz {
  0% {
    width: 250px;
    border-radius: 3px;
    transform: translate3d(0, 0, 0) scale(1);
    animation-timing-function: $easeInCubic;
  }
  20% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(90px, 0, 0) scale(1);
    animation-timing-function: $easeOutCubic;
  }
  100% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(30px, 0, 0) scale(50);
    animation-timing-function: $easeInCubic;
  }
}

@keyframes circle-end-quiz-phone {
  0% {
    width: calc(100vw - 4rem);
    border-radius: 3px;
    transform: translate3d(0, 0, 0) scale(1);
    animation-timing-function: $easeInCubic;
  }
  20% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(calc(50vw - 4rem), 0, 0) scale(1);
    animation-timing-function: $easeOutCubic;
  }
  100% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(calc(50vw - 4rem), 0, 0) scale(50);
    animation-timing-function: $easeInCubic;
  }
}

@keyframes circle-in {
  100% {
    width: 130px;
    border-radius: 3px;
    transform: translate3d(0, 0, 0) scale(1);
    animation-timing-function: $easeOutCubic;
  }
  40% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(30px, 0, 0) scale(1);
    animation-timing-function: $easeOutCubic;
  }
  0% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(30px, 0, 0) scale(0);
    animation-timing-function: $easeInCubic;
  }
}

@keyframes circle-in-phone {
  100% {
    width: calc(100% - 8rem);
    border-radius: 3px;
    transform: translate3d(0, 0, 0) scale(1);
    animation-timing-function: $easeOutCubic;
  }
  40% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(calc(50% - 8rem), 0, 0) scale(1);
    animation-timing-function: $easeOutCubic;
  }
  0% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(calc(50% - 8rem), 0, 0) scale(0);
    animation-timing-function: $easeInCubic;
  }
}

@keyframes circle-in-results {
  100% {
    width: 250px;
    border-radius: 3px;
    transform: translate3d(0, 0, 0) scale(1);
    animation-timing-function: $easeOutCubic;
  }
  40% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(90px, 0, 0) scale(1);
    animation-timing-function: $easeOutCubic;
  }
  0% {
    width: 70px;
    border-radius: 35px;
    transform: translate3d(90px, 0, 0) scale(0);
    animation-timing-function: $easeInCubic;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    animation-timing-function: $easeInCubic;
  }
  25% {
    transform: scale(1.08);
    animation-timing-function: $easeOutCubic;
  }
  50% {
    transform: scale(1);
    animation-timing-function: $easeOutCubic;
  }
  100% {
    transform: scale(1);
  }
}
